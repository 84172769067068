//MONTH
$january: #6271b6;
$february: #42a9ce;
$march: #42cece;
$april: #42baa0;
$may: #49d35f;
$june: #68b61b;
$july: #ded41b;
$august: #ff782c;
$september: #ce6c4d;
$october: #ce4d4d;
$november: #bd6d97;
$december: #8d6ccb;

//SYSTEM COLORS
$error: #c33e38;
$red-pressed: #efccca;
$red-hover: #efccca;
$success: #67ac5b;
$warning: #f09b43;

//BLUE
$blue-7: #124da8;
$blue-6: #1f66d1;
$brand-blue-1: #02abe3;
$blue-4: #81b3ff;
$blue-3: #b8d5fd;
$blue-2: #c7d9f4;
$blue-1: #e9f0fa;
$brand-blue-1: #02abe3;
$brand-blue-4: #4791ce;
$background-message: #e6f0ff;

//GREY-BLUE
$brand-blue-5: #024d7c;
$grey-blue-3: #7388ae;
$grey-blue-2: #b4bfd3;
$grey-blue-1: #f4f6f9;

//BLACK
$black-4: #212121;
$black-3: #666666;
$black-2: #b3b3b3;
$black-1: #e0e0e0;

//WHITE
$white: #ffffff;

//SINGLE COLORS
$rippling-brown: #4b2f3b;
