@import 'variables';
@import 'typography';
@import 'mixins';
@import 'colors';
@import 'animations';

html,
body {
  padding: 0;
  margin: 0;
  font-size: 10px;
  scroll-behavior: smooth;
  background-color: $grey-blue-1;
}

a {
  font-family: $title-font-family;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

* {
  box-sizing: border-box;
  font-family: $title-font-family, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.calendarContainer {
  .calendarButton {
    background: $brand-blue-5;

    &:hover {
      background: $brand-blue-5;
      transition: 0.4s;
      opacity: 0.8;
    }
  }

  .dayWrapper.selected button {
    background: $brand-blue-1;
  }

  .dayWrapper.today button {
    border: 3px solid $brand-blue-5 !important;
  }
}

.tether-element {
  @include if-between(md, xl) {
    left: -50px !important;
  }

  @include if-smaller-than(md) {
    left: 50% !important;
    position: absolute;
    transform: translateX(-50%) translateY(631px) translateZ(0px) !important;
  }
}
