$device-xs: 0;
$device-sm: 576px;
$device-md: 768px;
$device-lg: 992px;
$device-xl: 1200px;
$device-xxl: 1400px;

// Breakpoints for mixins
$responsive-breakpoints: (
  xs: $device-xs,
  sm: $device-sm,
  md: $device-md,
  lg: $device-lg,
  xl: $device-xl,
  xxl: $device-xxl,
);

:export {
  xs: $device-xs;
  sm: $device-sm;
  md: $device-md;
  lg: $device-lg;
  xl: $device-xl;
  xxl: $device-xxl;
}
